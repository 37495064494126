import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useRef, useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { CheckoutStep, SuccessStep } from '../Checkout/helpers';
import { EventType, OpenStepPayload, dispatch } from '../Checkout/events';
import { decodeUrl, encodeUrl } from '../../common/helpers';
import useEventHandler from './useEventHandler';
import useMediaDevice from '../../common/useMediaDevice';
import useScroll from '../../common/useScroll';

interface EmbedContextValue {
  embedded?: boolean;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  scrollTop?: () => void;
  scrollToSection?: (name: string, extraOffset?: number) => void;
}

export const EmbedContext = createContext<EmbedContextValue>({});

const isOpen = () => document.body.clientWidth > 180;

export interface EmbedProviderProps {
  step?: CheckoutStep | typeof SuccessStep;
  eventId?: string;
  embedded?: boolean;
  open?: boolean;
  children?: ReactNode;
}

const EmbedProvider = ({
  step, eventId, embedded: initialEmbedded, open: initialOpen, children,
}: EmbedProviderProps) => {
  const history = useHistory();
  const { search } = useLocation();
  const device = useMediaDevice();
  const { scrollTo, scrollToIfInvisible } = useScroll();

  const embedded = typeof initialEmbedded !== 'undefined' ? initialEmbedded : (
    // The checkout is embedded when the parent window differs from the current window.
    // When running in Cypress, the parent window must also differ from the top window,
    // because the top window is Cypress' spec runner.
    window.parent.location !== window.location && (!window.Cypress || window.top !== window.parent)
  );
  const [open, setOpen] = useState(initialOpen);

  // If the checkout is embedded, don't scroll the window, but the PageContainer div
  const scrollContainer = embedded && !device.isSmall ? 'PageContainer' : undefined;

  // Account for the widget title bar
  const scrollOffset = embedded ? 48 : 0;

  const scrollTop = () => {
    scrollToIfInvisible('CheckoutForm', {
      gutter: device.isSmall ? 0.75 : 1,
      scrollContainer,
      offset: scrollOffset,
    });
  };

  const scrollToSection = (name: string, extraOffset = 0) => {
    scrollTo(name, {
      gutter: 0.75,
      scrollContainer,
      offset: scrollOffset + extraOffset,
    });
  };

  useEffect(() => {
    dispatch(EventType.Initialized);

    const handleResize = () => {
      setOpen(isOpen());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openRef = useRef(open);

  useEffect(() => {
    if (open !== openRef.current) {
      if (open === false) {
        dispatch(EventType.WidgetClosed, {
          event: {
            id: eventId,
          },
        });

        if (step === SuccessStep) {
          // Next time the embed is opened, don't show the success message
          history.push(`/${eventId}${search}`);
        }
      } else if (open === true) {
        dispatch(EventType.WidgetOpened, {
          event: {
            id: eventId,
          },
        });
      }

      openRef.current = open;
    }
  }, [open, eventId, history, search, step]);

  useEventHandler(EventType.OpenStep, (payload: OpenStepPayload) => {
    const { step: newStep, event, params } = payload;

    if (event.id !== eventId || newStep !== step) {
      const oldParams = decodeUrl(search.replace(/^\?/, ''));
      const encodedParams = encodeUrl({ ...oldParams, ...params });
      history.push(`/${event.id}/${newStep}?${encodedParams}`);
    }

    setOpen(true);
  });

  return (
    <EmbedContext.Provider
      value={{
        embedded, open, setOpen, scrollTop, scrollToSection,
      }}
    >
      {embedded && open && <EmbedOpenStyle />}
      {eventId && children}
    </EmbedContext.Provider>
  );
};

const EmbedOpenStyle = createGlobalStyle`
  body {
    background: transparent !important;
  }
`;

export default EmbedProvider;
