import { ApolloError, ServerError, useApolloClient } from '@apollo/client';
import { ArrowDown, ArrowUp, Check, RefreshCw } from 'react-feather';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  GetRegistrationsForSaleQuery as GetRegistrationsForSale,
  GetRegistrationsForSaleQueryVariables as GetRegistrationsForSaleVariables,
  GetResaleLayoutQuery as GetResaleLayout,
} from '__generated__/graphql';

import { TitleBar } from '../Common/Layout';
import { getServerErrors, getStatusCode, isValidLocale } from '../../common/helpers';
import { isSupportedLocale } from '../../i18n';
import { setHeaders } from '../../api/apollo';
import { useTracking } from '../../common/Tracking';
import GetRegistrationsForSaleQuery from './GetRegistrationsForSaleQuery';
import PageLoader from '../Common/PageLoader';
import TicketCard from './TicketCard';
import Turnstile from './Turnstile';
import UI from '../../common/UI';
import useLazyQuery from '../../api/useLazyQuery';
import useLocale from '../../common/useLocale';
import useMediaDevice from '../../common/useMediaDevice';
import usePersistentThrottler from './usePersistentThrottler';
import useUrlState from '../../common/useUrlState';

type Event = GetResaleLayout['event'];

interface TurnstileHeaders {
  'Turnstile-Token'?: string;
}

export interface OverviewPageProps {
  event: Event;
  perPage?: number;
  maxLimit?: number;
}

const OverviewPage = ({
  event,
  perPage = 10,
  maxLimit = 100,
}: OverviewPageProps) => {
  const { t } = useTranslation();
  const { locale, formatNumber, formatDate } = useLocale();
  const { track } = useTracking();

  const [urlState, setUrlState] = useUrlState({
    parse: (params, { string, integer }) => ({
      ...params,
      locale: isValidLocale(params.locale) && isSupportedLocale(params.locale) ? params.locale : null,
      ticket: string(params.ticket),
      limit: Math.min(integer(params.limit, perPage), maxLimit),
    }),
  });

  const variables = {
    id: event.id,
    tickets: urlState.ticket ? [urlState.ticket] : null,
    limit: urlState.limit,
  };

  const client = useApolloClient();

  // Restore from Apollo cache, to prevent flickering when the
  // user presses the back button after opening a registration.
  const [data, setData] = useState<GetRegistrationsForSale>(client.cache.readQuery({
    query: GetRegistrationsForSaleQuery,
    variables,
  }));

  const [error, setError] = useState<ApolloError>();

  const [throttled, setThrottled] = usePersistentThrottler({
    key: 'overview',
    interval: 15000,
  });

  // Turnstile verification is not required by default.
  const [turnstileChallenge, setTurnstileChallenge] = useState<string | null>(null);

  const setTurnstileToken = (token: string | null) => {
    setHeaders((headers: TurnstileHeaders) => {
      if (token) {
        // Bot protection: replace the first occurrence of the character _ with ~ to
        // indicate that the token was passed on by the front-end app.
        headers['Turnstile-Token'] = token.replace('_', '~');
      } else {
        delete headers['Turnstile-Token'];
      }

      return headers;
    });
  };

  // Using a lazy query instead of a normal useQuery() with 'skip' parameter, because useQuery()'s
  // onCompleted and onError callbacks are not always executed when the query is re-run after the
  // skip parameter flips from true to false. Instead, we're manually fetching the data in a useEffect.
  const [fetch, { loading }] = useLazyQuery<GetRegistrationsForSale, GetRegistrationsForSaleVariables>(
    GetRegistrationsForSaleQuery,
    {
      variables,
      onCompleted: (data) => {
        // Turnstile tokens can be validated only once.
        setTurnstileToken(null);

        // Only update the data after it has been fetched. Apollo caches the previous result by default,
        // and if the data has changed since the last time it was fetched with the same variables, the
        // UI may first jump back to the cached data and then to the updated data, causing unecessary
        // flickering.
        setData(data);
        setError(undefined);

        // Disable the refresh button for 10 seconds.
        setAllowRefresh(false);
        setLastRefresh(new Date());

        // Track refresh
        track?.('Resale:OverviewRefreshed', {
          registrations_count: data.event.filtered_registrations_for_sale_count,
          urlState,
        });

        // Reset the ticket filter if the ticket is no longer available.
        const ticketIds = data.event.tickets_for_resale.map((ticket) => ticket.id);

        if (urlState.ticket && !ticketIds.includes(urlState.ticket)) {
          setUrlState((params) => ({
            ...params,
            ticket: undefined,
            limit: undefined,
          }), true);
        }
      },
      onError: (error) => {
        const challenge = (error.networkError as ServerError)?.response?.headers.get('Turnstile-Status');

        if (challenge) {
          setTurnstileChallenge(challenge);
        } else {
          // When throttled by the server, show an error message and temporarily block the UI.
          // The UI is automatically unblocked after 30 seconds.
          setThrottled(getStatusCode(error) === 429 || !!getServerErrors(error)?.event?.too_many_attempts);
        }
      },
      notifyOnNetworkStatusChange: true, // Triggers the loader when refetching.
    },
  );

  const resaleEnabled = event.enable_public_resale && event.payment_methods.length > 0;

  // Fetch registrations for sale.
  useEffect(() => {
    if (resaleEnabled && !throttled) {
      fetch();
    }
  }, [resaleEnabled, throttled, fetch]);

  const tickets = data?.event.tickets_for_resale;
  const registrations = data?.event.registrations_for_sale;

  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [allowRefresh, setAllowRefresh] = useState(true);

  // After data is fetched, allow the next manual refresh after 10 seconds.
  useEffect(() => {
    if (!allowRefresh) {
      const timer = window.setTimeout(() => {
        setAllowRefresh(true);
      }, 10000);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [allowRefresh]);

  const calledRef = useRef(false);

  useEffect(() => {
    if (data && !calledRef.current) {
      track?.('Resale:OverviewOpened', {
        registrations_count: data.event.filtered_registrations_for_sale_count,
        ticket_filter: urlState.ticket,
      });

      calledRef.current = true;
    }
  }, [track, data, urlState.ticket]);

  const handleRefresh = () => {
    if (urlState.limit > perPage) {
      // Resetting the limit will trigger a refetch.
      setUrlState((params) => ({
        ...params,
        limit: undefined,
      }), true);
    } else {
      // Manually refetch.
      fetch();
    }
  };

  const handleSelectTicket = (event: ChangeEvent<HTMLSelectElement>) => {
    // Button will get disabled again after the data has been fetched.
    setAllowRefresh(true);

    setUrlState((params) => ({
      ...params,
      ticket: event.target.value || undefined,
      limit: undefined,
    }), true);
  };

  const showMore = () => {
    // Button will get disabled again after the data has been fetched.
    setAllowRefresh(true);

    setUrlState((params) => ({
      ...params,
      limit: params.limit + perPage,
    }), true);
  };

  const showLess = () => {
    // Button will get disabled again after the data has been fetched.
    setAllowRefresh(true);

    setUrlState((params) => ({
      ...params,
      limit: undefined,
    }), true);
  };

  const onToken = (token: string) => {
    setTurnstileChallenge(null);
    setTurnstileToken(token);
    fetch();
  };

  const device = useMediaDevice();

  return (
    <>
      {event.resale_description && (
        <>
          <UI.HR style={{ borderColor: 'rgba(0, 0, 0, 0.10)' }} />
          <UI.Div sc={{ color: 'gray.800' }}>
            <UI.HTML html={event.resale_description} />
          </UI.Div>
        </>
      )}

      <UI.Div>
        <TitleBar>
          <UI.Div />
          <UI.FadeIn>
            {t('ticket_resale')}
          </UI.FadeIn>
        </TitleBar>

        <UI.Card sc={{ active: true }}>
          <UI.FormGrid style={{ minHeight: device.isSmall ? 134 : 118 }}>
            {turnstileChallenge && <Turnstile challenge={turnstileChallenge} onSuccess={onToken} />}
            {!turnstileChallenge && (
              <>
                {!data && throttled && (
                  <UI.FadeIn sc={{ color: 'gray.500', textAlign: 'center' }}>
                    <PageLoader height={80} throttled />
                  </UI.FadeIn>
                )}
                {data && (
                  <UI.FormGrid>
                    <UI.FadeIn>
                      <UI.Legend sc={{ fontWeight: 500, mb: 1 }}>
                        <UI.Delimit>
                          <UI.Span>
                            {t('resale.n_tickets_available', {
                              value: formatNumber(data.event.registrations_for_sale_count),
                              count: data.event.registrations_for_sale_count,
                            })}
                          </UI.Span>
                          <UI.Span>
                            {t('resale.n_tickets_sold', {
                              value: formatNumber(data.event.sold_registrations_count),
                              count: data.event.sold_registrations_count,
                            })}
                          </UI.Span>
                        </UI.Delimit>
                      </UI.Legend>

                      <UI.A
                        role="button"
                        onClick={handleRefresh}
                        sc={{
                          disabled: loading || !allowRefresh || throttled,
                          color: loading || !allowRefresh || throttled ? 'gray.600' : undefined,
                        }}
                      >
                        <UI.Icon sc={{ spin: loading || throttled }}>
                          <RefreshCw />
                        </UI.Icon>
                        {!loading && !throttled && (
                          <UI.FadeIn as="span">
                            {' '}
                            {allowRefresh ? t('resale.refresh') : t('resale.refreshed_at', {
                              time: formatDate(lastRefresh, { format: 'internal_time' }),
                            })}
                          </UI.FadeIn>
                        )}
                        {throttled && (
                          <UI.FadeIn as="span">
                            {' '}
                            {t('common:validation.too_many_attempts')}
                          </UI.FadeIn>
                        )}
                      </UI.A>
                    </UI.FadeIn>

                    {(urlState.ticket || tickets.length > 1) && (
                      <UI.FadeIn>
                        <UI.InputGroup>
                          <UI.InputWrapper>
                            <UI.Icon sc={{ muted: !urlState.ticket || throttled }}>
                              <UI.Icons.Ticket />
                            </UI.Icon>
                            <UI.Select
                              value={urlState.ticket || ''}
                              onChange={handleSelectTicket}
                              sc={{ hasValue: !!urlState.ticket, pl: 5 }}
                              style={{ fontWeight: 500 }}
                              aria-label={t('resale.filter_tickets')}
                              disabled={throttled}
                            >
                              <option value="">
                                {t('resale.show_all_tickets')}
                              </option>
                              {tickets.map((ticket, index) => (
                                <option value={ticket.id} key={index}>
                                  {ticket.title}
                                </option>
                              ))}
                            </UI.Select>
                          </UI.InputWrapper>
                        </UI.InputGroup>
                      </UI.FadeIn>
                    )}

                    {registrations.length > 0 && (
                      <UI.GridContainer sc={{ gutter: 0.5 }}>
                        {registrations.map((registration) => (
                          <UI.FadeIn key={registration.id}>
                            <TicketCard registration={registration} event={event} />
                          </UI.FadeIn>
                        ))}
                      </UI.GridContainer>
                    )}

                    {registrations.length === 0 && (
                      <UI.Info>
                        {t('resale.no_resale_tickets_available')}
                      </UI.Info>
                    )}

                    {perPage < data.event.filtered_registrations_for_sale_count && (
                      <UI.FlexContainer sc={{ justifyContent: 'center' }}>
                        {urlState.limit > perPage && (
                          <UI.Button
                            onClick={() => showLess()}
                            sc={{ blank: true, color: 'gray.500', mx: 2 }}
                            disabled={throttled}
                          >
                            <UI.Icon>
                              <ArrowUp />
                            </UI.Icon>
                            {' '}
                            {t('show_less')}
                          </UI.Button>
                        )}
                        {urlState.limit < data.event.filtered_registrations_for_sale_count && (
                          <UI.Button
                            onClick={() => showMore()}
                            sc={{ blank: true, mx: 2, muted: loading }}
                            disabled={urlState.limit >= maxLimit || throttled}
                          >
                            <UI.Icon>
                              <ArrowDown />
                            </UI.Icon>
                            {' '}
                            {t('resale.show_more')}
                          </UI.Button>
                        )}
                      </UI.FlexContainer>
                    )}
                  </UI.FormGrid>
                )}
                {!resaleEnabled && (
                  <UI.Warning>
                    {t('resale.resale_not_enabled_for_this_event')}
                  </UI.Warning>
                )}
                {resaleEnabled && !data && !error && !throttled && (
                  <UI.PageLoader height="100%" />
                )}
              </>
            )}
          </UI.FormGrid>
        </UI.Card>
      </UI.Div>

      <UI.Div sc={{ textAlign: 'center', color: 'gray.600' }}>
        <UI.Icon
          sc={{ background: 'primary', color: 'white', padding: 0.25, mr: 1 }}
          style={{ borderRadius: '100%', fontSize: '0.75em' }}
        >
          <Check strokeWidth={3} />
        </UI.Icon>
        <UI.Span sc={{ mr: 4 }} style={{ fontWeight: 500 }}>
          {t('resale.verified_tickets')}
        </UI.Span>
        <UI.Icon
          sc={{ background: 'primary', color: 'white', padding: 0.25, mr: 1 }}
          style={{ borderRadius: '100%', fontSize: '0.75em' }}
        >
          <Check strokeWidth={3} />
        </UI.Icon>
        <UI.Span style={{ fontWeight: 500 }}>
          {t('resale.100_percent_secure')}
        </UI.Span>
      </UI.Div>

      {resaleEnabled && (
        <>
          <UI.HR />

          <UI.Div sc={{ textAlign: 'center' }}>
            <UI.Strong>
              {t('resale.want_to_sell_ticket')}
            </UI.Strong>
            <UI.Div>
              <Trans i18nKey="resale.how_to_sell_ticket">
                <UI.A
                  href={`/p/${event.project.id}?locale=${locale}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ fontWeight: 500 }}
                />
              </Trans>
            </UI.Div>
          </UI.Div>
        </>
      )}
    </>
  );
};

export default OverviewPage;
