import { gql } from '__generated__';

export default gql(`
  query GetResaleLayout($id: ID!) {
    event(id: $id) {
      id
      locale
      brand_color
      title
      resale_description
      email
      website_url
      location {
        id
        title
        description
      }
      logo {
        id
        url
      }
      start_date
      end_date
      checkout_url
      enable_public_resale
      payment_methods
      active
      project {
        id
        organisation_name
        organisation_email
        organisation_address
        organisation_zip_code
        organisation_city
        organisation_country
        shift_vat
        locale
        timezone
        privacy_statement
        google_analytics
        facebook_pixel
        genders
      }
    }
  }
`);
