import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { useCheckoutSummary } from './CheckoutSummaryProvider';
import CheckoutContext from './CheckoutContext';
import StickyHeader from '../Common/StickyHeader';
import Timer from '../Common/Timer';
import UI from '../../common/UI';

const CheckoutTimers = ({ isSticky }: { isSticky?: boolean; }) => {
  const { t } = useTranslation();
  const { checkoutSummary } = useCheckoutSummary();
  const { session } = useContext(CheckoutContext);

  const hasTickets = checkoutSummary.tickets.length > 0;
  const timeLeft = checkoutSummary.time_left;
  const totalTime = checkoutSummary.total_time || 0;

  if (!session.showTimers || typeof timeLeft !== 'number') {
    return <></>;
  }

  // Only show the "Time left to select a ticket" message if the user went through the queue.
  const isVisible = timeLeft > 0 && (!!session.queueToken || hasTickets);

  return (
    <CheckoutTimersContainer isVisible={isVisible}>
      <StickyHeader isActive={isSticky}>
        <UI.Div sc={{ px: [3, 4], py: 3 }}>
          <Timer
            secondsLeft={isVisible ? timeLeft : 0}
            totalTime={totalTime}
          >
            {(hasTickets || !isVisible)
              ? t('checkout_timers.time_left_to_finish_registration')
              : t('checkout_timers.time_left_to_select_tickets')}
          </Timer>
        </UI.Div>
      </StickyHeader>
    </CheckoutTimersContainer>
  );
};

export const CheckoutTimersContainer = styled(UI.Div)<{ isVisible?: boolean; }>`
  ${({ isVisible }) => css`
    padding: 0;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
    height: ${isVisible ? 58 : 0}px;
    border-bottom: 1px solid rgba(0, 0, 0, ${isVisible ? '0.1' : '0'});
    opacity: ${isVisible ? 1 : 0}
  `}
`;

export default CheckoutTimers;
