import 'core-js/actual';
import 'intl-pluralrules';
import 'whatwg-fetch';

import { createRoot } from 'react-dom/client';
import Clarity from '@microsoft/clarity';

import '../setup';

import { inProductionEnvironment } from '../common/helpers';
import { setupTranslations } from '../i18n';
import App from './App';

setupTranslations({
  namespaces: [
    'frontend',
    'common',
  ],
});

if (inProductionEnvironment()) {
  Clarity.init('qx81pab6wq');
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
