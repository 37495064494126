import { ReactNode, useContext } from 'react';
import styled, { css } from 'styled-components';

import { EmbedContext } from './EmbedProvider';
import UI from '../../common/UI';
import useMediaDevice from '../../common/useMediaDevice';
import useStickyElement from '../../common/useStickyElement';

interface StickyHeaderProps {
  isActive?: boolean;
  background?: string;
  children?: ReactNode | ((isSticky: boolean) => ReactNode);
}

const StickyHeader = ({ isActive = true, background, children }: StickyHeaderProps) => {
  const { embedded } = useContext(EmbedContext);
  const device = useMediaDevice();

  const { anchorRef, elementRef, isSticky, position } = useStickyElement({
    containerId: 'CheckoutContainer',
    scrollContainerId: embedded && !device.isSmall ? 'PageContainer' : undefined,
    offset: embedded ? 48 : 0,
    floatingElementHeight: 58,
  });

  return (
    <UI.Div sc={{ padding: 0 }} ref={anchorRef}>
      <HeaderContainer ref={elementRef} isSticky={isSticky && isActive} background={background} {...position}>
        {typeof children === 'function' ? children(isSticky) : children}
      </HeaderContainer>
    </UI.Div>
  );
};

interface HeaderContainerProps {
  isSticky: boolean;
  top: number | string;
  right: number | string;
  left: number | string;
  background?: string;
}

const HeaderContainer = styled(UI.Div)<HeaderContainerProps>`
  ${({ isSticky, top, right, left, background, theme }) => css`
    background: ${theme.getColor(background || 'white')};

    > ${UI.Div} {
      padding: ${theme.gutter * 0.75}px;

      @media ${theme.devices.md} {
        padding-left: ${theme.gutter}px;
        padding-right: ${theme.gutter}px;
      }
    }

    ${isSticky && css`
      position: fixed;
      top: ${top}px;
      left: ${left}px;
      right: ${right}px;
      z-index: 100;
      border-bottom: 1px solid rgba(0, 0, 0, .08);

      > ${UI.Div} {
        padding-left: ${theme.gutter * 1.25}px;
        padding-right: ${theme.gutter * 1.25}px;

        @media ${theme.devices.md} {
          padding-left: ${theme.gutter}px;
          padding-right: ${theme.gutter}px;
          max-width: ${theme.breakpoints.md - 2 * theme.gutter * 0.75}px;
          margin: 0 auto;

          @media ${theme.devices.md} {
            padding-left: ${theme.gutter}px;
            padding-right: ${theme.gutter}px;
            max-width: ${theme.breakpoints.md - 2 * theme.gutter}px;
          }
        }
      }
    `}
  `}
`;

export default StickyHeader;
